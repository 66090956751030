import React from "react"
import BookImage from '../components/bookImage';
import Layout from "../components/layout"
import SEO from "../components/seo"

const Writing = () => (
  <Layout>
    <SEO title="Writing" />
    <div className="summary">
      <p>
      I've written for several publications. My technical writing is about understanding when to use features, not just how.
      </p>
      <p>
        My non-technical writing focuses on larger themes of code in the world.
      </p>
    </div>
    <div>
      <h2>Books</h2>
      <div style={{width: 300, margin: '20px 0' }}>
        <BookImage />
      </div>
      <p>
        <a href="https://pragprog.com/book/es6tips/simplifying-javascript">
          Simplifying JavaScript
        </a>
      </p>
      <blockquote>
        You should probably jot down each Tip from this book on Post-It notes and plaster them all over your desk area. These simple, practical bits of advice will have a profound effect on simplifying your JavaScript. -- Kyle Simpson
      </blockquote>
      <blockquote>
          Joe provides a clear, organized path to mastering key JavaScript concepts. This isn’t a boring textbook. It’s a playbook filled with practical, real-world approaches to writing modular, maintainable, and modern JavaScript. -- Cory House Pluralsight Author and International Speaker and Consultant
      </blockquote>
      <h2>Technical Writing</h2>
      <ul>
        <li>
          <h3>Digital Ocean</h3>
          <ul>
            <li>Recruited to write a 20-part React series. Articles have received over a quarter million views.</li>
            <li>
              <a href="https://www.digitalocean.com/community/users/joemorgan">
                Complete Series
              </a>
            </li>
          </ul>
        </li>
        <li>
          <h3>FreeCodeCamp</h3>
          <ul>
            <li>
              <a href="https://medium.freecodecamp.org/dont-settle-how-you-can-match-your-javascript-collection-to-your-goals-c94cb994be4e">
              Don't Settle: How You Can Match JavaScript Collections to Your Goals
              </a>
            </li>
          </ul>
        </li>
        <li>
          <h3>Pony Foo</h3>
          <ul>
            <li>
              <a href="https://medium.com/@jsmapr1/react-state-choose-wisely-1787ee1bf18a">
                React State: 3 Ways
              </a>
            </li>
            <li>
              <a href="https://medium.com/@jsmapr1/disguise-driven-testing-jest-mocks-in-depth-pt-1-ffdb4631e76c">
                Disguise Driven Testing: Jest Mocks in Depth
              </a>
            </li>
          </ul>
        </li>

      </ul>
      <h2>Non Technical Writing</h2>
      <ul>
        <li>
          <h3>Slate</h3>
          <ul>
            <li>
              <p>
                <a href="https://slate.com/human-interest/2018/12/against-teaching-kids-to-code-creativity-problem-solving.html">
                  I’m a Developer. I Won’t Teach My Kids to Code, and Neither Should You.
                </a>
              </p>
              <p>
                <a href="https://www.slate.fr/story/171660/education-apprendre-code-developpement-informatique-enfants-inutile">
                  French translation
                </a>
              </p>
              <p>
                <a href="https://slate.com/briefing/2018/12/slate-most-popular-stories-of-2018.html">
                  A top article of 2019.
                </a>
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </Layout>
)

export default Writing
